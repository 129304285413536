<template>
	<!-- 坤典云授权 -->
	<div class="kd-cloud-auth">
		<a-row :gutter="[16,16]" v-if="step == 2">
			<a-col :span="24">
				<div class="ca-header">
					<div class="ca-header-left">
						<img class="logo" src="@/assets/img/kd-logo.png" alt="">
						<div class="infos">
							<div class="kdyun">坤典云</div>
							<div><span class="f12">授权账户：</span>{{infos ? infos.auth.mobile :"" }}</div>
							<div>
								<span class="f12">授权域名：</span>{{infos ? infos.auth.site_url :"" }}
								<a-button @click="show.auth = true" type="link">重新授权</a-button>
							</div>
						</div>
					</div>
					<div class="ca-header-right">
						<a-space>
							<!-- <span>账户余额：</span> -->
							<!-- <span class="money">2456.00</span> -->
							<!-- <a-button type="primary">充值</a-button>
							<a-button>交易记录</a-button> -->
						</a-space>
					</div>
				</div>
			</a-col>
			<a-col :span="8">
				<div class="ca-infos">
					<div class="title">云存储空间</div>
					<div class="total">
						<p><span style="color:#FF0066;">●</span>已使用：{{ossInfo?.use_size}}</p>
						<p><span style="color:#00CC66;">●</span>总空间：{{ossInfo?.max_size}}</p>
					</div>
					<div class="ossEchart" id="ossEchart"></div>
				</div>
			</a-col>
			<a-col :span="8">
				<div class="ca-infos">
					<div class="title">短信用量</div>
					<div class="total">
						<p><span style="color:#FF0066;">●</span>已使用：{{infos ? infos.sms.used_num :0}}条</p>
						<p><span style="color:#00CC66;">●</span>总条数：{{infos ? infos.sms.all_num :0}}条</p>
					</div>
					<a-space>
						<!-- <a-button type="link">购买短信</a-button> -->
						<a-button type="link" @click="getShortReocrd(1,10)">发送记录</a-button>
					</a-space>
					<div class="ossEchart" id="shortEchart"></div>

				</div>
			</a-col>
			<a-col :span="4">
				<div class="ca-infos">
					<div class="title">国标设备</div>
					<div class="device">
						<div>
							<span class="special">{{infos ? infos.qiNiuDevice.used_num:0}}</span>/
							<span>{{infos?infos.qiNiuDevice.all_no_free_device_num :0}}</span>
						</div>
						<div><span class="f12">已添加/授权总量</span></div>
						<!-- <a-space>
							<a-button type="link">添加授权</a-button>
							<a-button type="link">购买设备</a-button>
						</a-space> -->
					</div>
				</div>
			</a-col>
			<a-col :span="4">
				<div class="ca-infos">
					<div class="title">物联网设备</div>
					<div class="device">
						<div><span class="special">{{infos?infos.internetTings :0 }}</span></div>
						<div><span class="f12">设备总量</span></div>
						<!-- <a-space>
							<a-button type="link">购买设备</a-button>
						</a-space> -->
					</div>
				</div>
			</a-col>

			<a-col :span="24">
				<div class="ca-flow">
					<div class="title">国标设备流量监控</div>
					<div class="ca-flow-con">
						<!-- <div class="left" style="margin-top:48px">
							<span class="f12">累计使用流量</span>
							<div style="margin-bottom:48px"><span class="num">20.4</span> Gb</div>

							<span class="f12">本周使用流量</span>
							<div><span class="num">1.36</span>	Gb</div>
						</div> -->
						<div class="right">
							<div id="flowEchart"  style="width:100%;height:300px"></div>
						</div>
					</div>
				</div>
			</a-col>
		</a-row>
		<div class="kca-step1" v-if="step == 1">
			<div class="kca-step1-title">坤典云授权</div>
			<div class="kca-step1-tip">授权后可使用</div>
			<div class="kca-step1-info">
				<div class="kcasi-item">
					<i class="ri-global-line ri-xl"></i>
					<span>物联网主机</span>
				</div>
				<div class="kcasi-item">
					<i class="ri-mail-line ri-xl"></i>
					<span>坤典云短信</span>
				</div>
				<div class="kcasi-item">
					<i class="ri-webcam-fill ri-xl"></i>
					<span>坤典云监控</span>
				</div>
			</div>
			<div class="kca-step-btn" @click="show.auth = true">立即授权</div>
		</div>

		<a-modal title="坤典云授权" v-model:visible="show.auth" :footer="null" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="登录账号">
					<a-input v-model:value="loginForm.mobile"></a-input>
				</a-form-item>
				<a-form-item label="登录密码">
					<a-input v-model:value="loginForm.password"></a-input>
				</a-form-item>
				<a-form-item :wrapper-col="{offset:4}">
					<a-space>
						<a-button type="primary" @click="sureAuth">确定授权登录</a-button>
						<a-button @click="registerKdAccount">注册账号</a-button>
					</a-space>
				</a-form-item>
			</a-form>
	    </a-modal>

		<a-modal title="短信发送记录" v-model:visible="show.short_record" 
			width="700px" 
			:footer="null" 
			@cancel="show.short_record = false">
			<a-table :pagination="false" size="small"  row-key="id" :data-source="shortReocrd.list" :columns="[
				{title:'id',dataIndex:'id'},
				{title:'发送手机号',dataIndex:'mobile'},
				{title:'短信签名',dataIndex:'sign'},
				{title:'短信模板',dataIndex:'SMS_172765068'},
				{title:'发送时间',dataIndex:'create_time'},
			]">
  			</a-table>
			<div class="pager">
				<a-pagination
				    show-size-changer
				    :default-current="shortReocrd.page"
				    :total="shortReocrd.count"
				    @showSizeChange="(p,e)=>{getShortReocrd(shortReocrd.page,e)}"
					@change="(e)=>{getShortReocrd(e,shortReocrd.limit)}"
				/>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import setModel from '@/api/set'
import liveModel from '@/api/live'
import * as echarts from 'echarts'
import commonModel from '@/api/common'
import walden from '@/assets/js/walden.js'
export default{
	setup(){
		const state = reactive({
			step:1,			//授权状态 1=未授权 2=已授权
			loginForm:{
				mobile:"",
				password:"",
			},
			show:{
				auth:false,
				short_record:false,
			},
			//授权信息
			authInfo:{
				cloud_thing_account_key:"",		//SSID
				cloud_thing_account_secret:"",	//SSK
			},	

			cloudKdOssEchart:null,
			cloudKdShortEchart:null,
			flowEchart:null,

			infos:null,
			ossInfo:null,

			shortReocrd:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		getAuthData()
		setTimeout(() => {
			initKdCloud()
		}, 1000)

		function initKdCloud(){
			commonModel.getImageList(1,1,null,res=>{
				state.ossInfo = res.size
				state.cloudKdOssEchart = echarts.init(document.getElementById("ossEchart"))
				state.cloudKdOssEchart.setOption({
					tooltip: { trigger: 'item' },
					legend: { top: '5%', left: 'center', show:false },
					series: [
						{
							name: '云储存空间',
							type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							label: {
								show: false,
								position: 'center'
							},
							emphasis: {
								label: {
									show: true,
									fontSize: '12',
									fontWeight: 'bold'
								}
							},
							labelLine: { show: false },
							data: [
								{value: parseFloat(res.size.use_size), name: '已使用',itemStyle: { color: '#1890ff' }},
								{value: parseFloat(parseFloat(res.size.max_size)-parseFloat(res.size.use_size)).toFixed(2), name: '未使用',itemStyle: { color: '#f0f2f5' }}
							]
						}
					]
				})
			
			})

			liveModel.getKdCloudInfos(res=>{
				state.infos = res

				state.cloudKdShortEchart = echarts.init(document.getElementById("shortEchart"))
				state.cloudKdShortEchart.setOption({
					tooltip: { trigger: 'item' },
					legend: { top: '5%', left: 'center', show:false },
					series: [
						{
							name: '云储存空间',
							type: 'pie',
							radius: ['40%', '70%'],
							avoidLabelOverlap: false,
							label: {
								show: false,
								position: 'center'
							},
							emphasis: {
								label: {
									show: true,
									fontSize: '12',
									fontWeight: 'bold'
								}
							},
							labelLine: { show: false },
							data: [
								{value: res.sms.used_num, name: '已使用',itemStyle: { color: '#33CC99' }},
								{value: parseInt(res.sms.all_num-res.sms.used_num), name: '未使用',itemStyle: { color: '#f0f2f5' }}
							]
						}
					]
				})
			})

			liveModel.getQiniuFlowTotal(res=>{
				console.log('flow',res);
				state.flowEchart = echarts.init(document.getElementById("flowEchart"),'walden')
				
				state.flowEchart.setOption({
					title : {
						text: '近一周流量用量',
						padding: [ 5, 10, 5, 100]
					},
					tooltip: {
						show:true,
						trigger: 'item'
					},
					legend: {
						data:['上传流量','下载流量']
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: res.time
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						name:'上传流量',
						data: res.data.up,
						type: 'line',
						smooth:true,
						areaStyle: {
							color:"rgba(30, 225, 255,0.2)"
						}
					},{
						name:'下载流量',
						data: res.data.down,
						type: 'line',
						smooth:true,
						areaStyle: {
							color:"rgba(30, 144, 255,0.2)"
						}
					}]
				})
			})
		}

		function getAuthData(){
			setModel.getFarmSet(['cloud_thing_account_key','cloud_thing_account_secret']).then(res=>{
				if( res.cloud_thing_account_key && res.cloud_thing_account_secret ){
					state.authInfo = {
						cloud_thing_account_key:res.cloud_thing_account_key,
						cloud_thing_account_secret:res.cloud_thing_account_secret,
					}
					state.step = 2
				}
			})
		}

		function sureAuth(){
			liveModel.kdCloudAuth(state.loginForm,res=>{
				state.authInfo = {
					cloud_thing_account_key:res.key,
					cloud_thing_account_secret:res.secret,
				}

				setModel.setFarmSet(state.authInfo)
				state.show.auth = false
				getAuthData()
			})
		}

		function registerKdAccount(){
			window.open("http://cloud.cqkundian.com/page")
		}

		function getShortReocrd(page,limit){
			state.show.short_record = true
			liveModel.getShortRecord(page,limit,res=>state.shortReocrd = {limit,...res})
		}

		return{
			...toRefs(state),
			sureAuth,
			registerKdAccount,
			getShortReocrd
		}
	}
}
</script>
sty
<style lang="scss">
	//new code 1.6.5
	.ca-header{
		background: #fff;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 24px;
		text-align: left;

		&-left{
			display: flex;
		}
		.logo{
			width: 72px;
			height: 72px;
			margin-right: 12px;
		}
		.kdyun{
			font-weight: bold;
			font-size: 16px;
		}
		.money{
			font-weight: bold;
			font-size: 18px;
		}
	}

	.ca-infos{
		background: #fff;
		text-align: left;
		padding: 12px 24px;
		height: 150px;
		.title{
			font-weight: bold;
		}
		.total{
			font-size: 13px;
			margin-top: 24px;

			span{
				margin-right: 8px;
			}
			p{
				line-height: 16px;
			}
		}
		.ossEchart{
			width: 200px;
			height: 100px;
			position: absolute;
			left: 120px;
			top: 36px;
		}
		
		.device{
			width: 100%;
			margin-top: 8px;
			text-align: center;	
			.special{
				font-size: 24px;
				font-weight: bold;
				color:#3399FF;
			}
		}
	}

	.ca-flow{
		background: #fff;
		text-align: left;
		padding: 12px 24px;
		.title{
			font-size: 16px;
			font-weight: bold;
			height: 40px;
			border-bottom: 1px solid #f4f4f4;
			margin-bottom: 12px;
		}

		&-con{
			display: flex;

			.num{
				font-size: 24px;
			}
			.left{
				width: 240px;
			}
			.right{
				text-align: left;
				width: calc(100% - 240px);
			}
		}
	}


	.kd-cloud-auth{
		text-align: center;
		min-height: 300px;
		// padding-top: 48px;
	}
	.kca-step-btn{
		width: 10%;
		height: 44px;
		line-height: 44px;
		text-align: center;
		background: #000;
		color: #fff;
		margin-left: 45%;
		margin-top: 30px;
		cursor: pointer;
		border-radius: 4px;
	}
	.kca-step1{
		&-title{
			font-size: 20px;
			font-weight: bold;
		}
		&-tip{
			margin: 12px 0;
			color: #999;
		}
		
		&-info{
			display: flex;
			justify-content: center;
			.kcasi-item{
				margin: 0 16px;
				display: flex;
				align-items: center;
				color: #666;
				i{
					color: #000000;
					margin-right: 6px;
				}
			}
		}
	}
	
	.kca-step2{
		&-logo{
			width: 100px;
			height: 100px;
			border-radius: 4rpx;
		}
		&-name{
			font-weight: bold;
			font-size: 20px;
			margin-top: 12px;
		}
		&-tip{
			font-size: 14px;
			color: #999;
		}
		&-val{
			width: 50%;
			margin-left: 25%;
			display: flex;
			align-items: center;
			min-height: 40px;
			justify-content: center;
			font-size: 14px;
			word-break: break-all;
			
			&-title{
				width: 137px;
				text-align: right;
				margin-right: 24px;
			}
			&-v{
				width: 300px;
				text-align: left;
				display: flex;
				align-items: center;
			}
			&-btn{
				width: 80px;
				height: 24px;
				text-align: center;
				background: #000000;
				color: #fff;
				line-height: 24px;
				margin-left: 12px;
				cursor: pointer;
			}
		}
	}

</style>
